<template>
  <div class="page-content">
    <div class="header">
      <el-card>
        <div class="top-btn">
          <el-button type="primary" @click="addHandler">
            添加
          </el-button>
        </div>
      </el-card>
    </div>
    <div class="body">
      <el-card>
        <el-table
            :data="dataList"
            style="width: 100%"
            border
            stripe
            lazy
            row-key="id"
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          <el-table-column prop="menuName" label="菜单名称" min-width="200"/>
          <el-table-column prop="icon" label="图标" align="center" width="150">
            <template v-slot="scoped">
              <i :class=" scoped.row.icon " style="font-size: 18px"></i>
            </template>
          </el-table-column>
          <el-table-column prop="menuType" label="菜单类型" align="center" width="100">
            <template v-slot="scoped">
              <el-tag :type=" scoped.row.menuType === 1? 'success':'danger'">
                {{ scoped.row.menuType === 1 ? '菜单' : '权限' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="path" label="路径" width="200"></el-table-column>
          <el-table-column prop="permission" label="权限标识" width="200"></el-table-column>
          <el-table-column prop="sortNum" label="排序" width="200"></el-table-column>
          <el-table-column label="操作" width="300" fixed="right" align="center">
            <template v-slot="scoped">
              <el-button type="primary" size="small" @click="addChildren(scoped.row.id)">
                添加子级
              </el-button>
              <el-button type="warning" size="small" @click="editHandler(scoped.row.id)">
                编辑
              </el-button>
              <el-button type="danger" size="small" @click="deleteHandler(scoped.row.id)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <add :add-data="addData" @closeDialog="closeDialog"/>
    <edit :edit-data="editData" @closeDialog="closeDialog"/>
    <add-child :add-child-data="addChildData" @closeDialog="closeDialog"/>
  </div>
</template>

<script>
import { delMenu, listTree } from '@/api/login.js'
import Edit from './dialog/edit'
import Add from './dialog/add'
import AddChild from './dialog/addChild'

export default {
  components: {
    AddChild,
    Add,
    Edit
  },
  data () {
    return {
      dataList: [],
      editData: {
        isOpen: false,
        id: ''
      },
      addData: {
        isOpen: false
      },
      addChildData: {
        isOpen: false,
        pid: ''
      }
    }
  },
  created () {
    this.renderTable()
  },
  methods: {
    renderTable () {
      listTree().then(res => {
        this.dataList = res.data
      })
    },
    addHandler () {
      this.addData.isOpen = true
    },
    editHandler (id) {
      this.editData.id = id
      this.editData.isOpen = true
    },
    addChildren (id) {
      this.addChildData.pid = id
      this.addChildData.isOpen = true
    },
    closeDialog (data) {
      this.addData.isOpen = data.isOpen
      this.editData.isOpen = data.isOpen
      this.addChildData.isOpen = data.isOpen
      if (data.type === 'success') { // 保存成功关闭弹窗后刷新
        this.renderTable()
      }
    },
    deleteHandler (id) {
      this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMenu(id).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.renderTable()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
